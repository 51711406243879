document.addEventListener("DOMContentLoaded", (event) => {
    const {
        initLeadForm
    } = WebflowCustomCode;

    initLeadForm();
});

window.WebflowCustomCode = (function() {

    function initLeadForm() {
        analytics &&
            analytics.ready(function () {
                const trackForms = document.querySelectorAll(
                    'form[data-tracking="lead-form"]'
                );
                
                trackForms.forEach((form) => {
                    addEnrichmentDataFields(form);
                    SegmentEvent.trackLeadFormSubmit(form);
                });
            });
    }

    function addEnrichmentDataFields(form, isHSForm = false) {
        const user = analytics.user ? analytics.user() : null;
        const pageTitleCleaned = document.title.split("|")[0].trim();
        const userTraits = getFromLocalStorage('ajs_user_traits');

        let values = {
            url: window.location.origin + window.location.pathname,
            query: window.location.search,
            "segment_user_id": user ? user.id() : "",
            "segment_anonymous_id": user ? user.anonymousId() : "",
            "current_page": pageTitleCleaned,
            referrer: document.referrer,
            "user_agent": navigator.userAgent,
            "marketing_data": JSON.stringify(
                ClickCollector.getClickIds()
            ),
            email: Object.keys(userTraits).length > 0 ? userTraits['email'] : '',
        };

        Object.entries(values).forEach(([name, value]) => {
            if (isHSForm) {
                if (name === 'marketing_data') {
                    value = JSON.stringify(values);
                }

                setHSInputValue(form, name, value);
            } else {
                appendInputFieldToForm(form, name, value)
            }
            
        });

        cbrCheckEmail();
    }

    function cbrCheckEmail() {
        const emailField = document.querySelector('[name="email"]');

        if (window.location.pathname.includes('/cbr/') && emailField && emailField.value.trim() == '' && !document.referrer.includes('/free-cost-benefit-report')) {
            window.location.href = '/free-cost-benefit-report';
        }
    }

    return {
        initLeadForm,
        addEnrichmentDataFields,
    };
})();
