window.CustomHubspotCode = (function () {
    function onFormSubmitted() {
        window.addEventListener("message", (event) => {
            const { data } = event;

            if (
                data.type === "hsFormCallback" &&
                data.eventName === "onFormSubmitted"
            ) {
                hsFormTrackLeadSubmitted(data);
                eoiFormRedirect(data, event);
            }
        });
    }

    function onFormReady() {
        window.addEventListener("message", (event) => {
            const { data } = event;

            if (
                data.type === "hsFormCallback" &&
                data.eventName === "onFormReady"
            ) {
                populateHSHiddenFields();
                initAddressAutocomplete('06049285-0fc0-4a6b-be17-c875bf3d4a62');
                initAddressAutocomplete('9265197a-d9ea-47e6-a9db-63b4d0568cd6');
                triggerContactUsInitAutocompleteAddress();
            }
        });
    }

    function triggerContactUsInitAutocompleteAddress() {
        const enquirySelect = document.querySelector('[name="what_is_the_nature_of_your_enquiry_"]');

        enquirySelect && enquirySelect.addEventListener('change', function () {
            if (this.value === 'Atlas Carbon grazier expression of interest') {
                setTimeout(() => {
                    initAddressAutocomplete('5adff120-90cf-4b6f-a41c-71725bb5471a');
                }, 100);
            } else {
                document.querySelector('#manual-address-ui').remove();
            }
        });
    }

    function hsFormTrackLeadSubmitted(data) {
        const { id: formId, data: eventData } = data;
        const { submissionValues } = eventData;

        if (
            !("firstname" in submissionValues) ||
            !("lastname" in submissionValues) ||
            !("email" in submissionValues)
        ) {
            console.error(
                "Data must have 'firstname', 'lastname', and 'email'."
            );
            return;
        }

        const { firstname, lastname, email, mobilephone, phone } = submissionValues;
        const phoneValue = mobilephone || phone || "";

        const identifyTraits = {
            first_name: firstname,
            last_name: lastname,
            email,
            phone: phoneValue ? standardisedPhoneNumber(phoneValue) : "",
        }

        SegmentEvent.identify(identifyTraits);

        const props = {
            ...identifyTraits,
            lead_type: 'hs_form',
            form_id: formId,
            form_data: submissionValues,
        }

        SegmentEvent.trackLeadSubmitted(props);
    }

    function populateHSHiddenFields() {
        const poll = setInterval(function () {
            if (ClickCollector.getHasCollectedIds()) {
                WebflowCustomCode.addEnrichmentDataFields($('[action*="https://forms.hsforms.com"]'), true);

                clearInterval(poll);
            }
        }, 700);
    }

    function initAddressAutocomplete(formId, options = {}) {
        const $form = $(`#hsForm_${formId}`);

        if ($form.length === 0) return;
        
        const { googleAutocompleteType = 'address' } = options;

        insertManualAddressUI();
        insertErrorMessageUI('complete-address-error-msg', 'Please enter the street number.');
        insertErrorMessageUI('complete-address-select', 'Please choose an address from the dropdown list.');
        insertErrorMessageUI('complete-address-required-field', 'Please choose an address from the dropdown list.');
        initAutocompleteField($form, googleAutocompleteType);
    }

    function insertManualAddressUI() {
        const addressField = `
            <div id="manual-address-ui" class="hs_show_address_fields hs-show_address_fields hs-fieldtype-booleancheckbox field hs-form-field" >
                <legend class="hs-field-desc" style="display: none;"></legend>
                <div class="input">
                    <ul class="inputs-list">
                        <li class="hs-form-booleancheckbox">
                            <label for="show_address_fields-9dcd0afd-4c99-4fde-b363-ff551216fb9c" class="hs-form-booleancheckbox-display">
                                <input id="show_address_fields-9dcd0afd-4c99-4fde-b363-ff551216fb9c" class="hs-input" type="checkbox" name="show_address_fields" value="true" data-gtm-form-interact-field-id="1">
                                <span>Can't find your address? Enter it manually.</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        `

        $(addressField).insertAfter($('div.hs_complete_address'));
    }

    function insertErrorMessageUI(id, message) {
        const errorEl = `
            <ul class="no-list hs-error-msgs inputs-list" role="alert" id="${id}" style="display: none">
                <li><label class="hs-error-msg hs-main-font-element">${message}</label></li>
            </ul>
        `;

        $(errorEl).insertAfter($('div.hs_complete_address div.input'));
    }

    function initAutocompleteField($form, type) {
        initializeAutocomplete(type);

        const geoFields = ['street_number', 'address', 'country', 'city', 'zip', 'state'];

        if ($form.find('[name="show_address_fields"]').is(':checked')) {
            showGeoFields(geoFields);
        } else {
            hideGeoFields(geoFields);
        }

        $form.find('[name="show_address_fields"]').change(function () {
            if ($(this).is(':checked')) {
                showGeoFields(geoFields);
            } else {
                hideGeoFields(geoFields);
            }
        });
    }

    function showGeoFields(geoFields) {
        geoFields.forEach(field => $(`[name=${field}]`).attr('type', 'text').closest('div.hs-form-field').show());
        $('#places-autocomplete-container').hide();
    }

    function hideGeoFields(geoFields) {
        geoFields.forEach(field => $(`[name=${field}]`).attr('type', 'text').closest('div.hs-form-field').hide());
        $('#places-autocomplete-container').show();
    }

    function eoiFormRedirect(data, event) {
        event.preventDefault();
        
        // CBR and Contact Us Forms
        const $form = $(`#hsForm_06049285-0fc0-4a6b-be17-c875bf3d4a62, #hsForm_5adff120-90cf-4b6f-a41c-71725bb5471a`);

        if ($form.length === 0) return;
        
        const { data: eventData } = data;
        const { submissionValues } = eventData;
        const { grazing_area_size_ac_cbr, annual_rainfall_cbr_ac } = submissionValues;
        console.info({ grazing_area_size_ac_cbr, annual_rainfall_cbr_ac });

        if (annual_rainfall_cbr_ac >= 400 && grazing_area_size_ac_cbr >= 800) {
            window.location.href = '/cbr/property';
        } else if (grazing_area_size_ac_cbr >= 150) {
            window.location.href = '/cbr/maia-recommendation';
        } else if (grazing_area_size_ac_cbr < 150) {
            window.location.href = '/cbr/ineligible';
        } else {
            window.location.href = '/thank-you';
        }
    }

    function hsFormSubmitClickListener() {
        if ($('input.hs-button').length == 0) return;

        $('input.hs-button').on('click', function (e) {
            const completeAddress = document.querySelector('[name="complete_address"]');
            const manualAddressCheckbox = document.querySelector('[name="show_address_fields"]');

            if (completeAddress && manualAddressCheckbox) {
                if (!manualAddressCheckbox.checked && completeAddress.value.trim() == '') {
                    e.preventDefault();
                    showHideErrorMsg('complete-address-required-field', true);
                } else {
                    showHideErrorMsg('complete-address-required-field', false);
                }
            }
        });
    }

    return {
        onFormSubmitted,
        onFormReady,
        hsFormSubmitClickListener,
    };
})();

CustomHubspotCode.onFormSubmitted();
CustomHubspotCode.onFormReady();
CustomHubspotCode.hsFormSubmitClickListener();